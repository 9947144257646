import React from "react";

export const GoogleTagManager = () => {
  return (
    <>
    {/* <!-- Google Tag Manager --> */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-135652949-8"></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-135652949-8');
          `,
      }}
    />
    </>
  );
};
